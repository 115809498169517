export { Vector2D } from "./vector2d"
export * from "./methods"

import { Vector2D } from "./vector2d"
import * as V from "./methods"

export default {
  Vector2D,
  ...V
}
